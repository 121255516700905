import { useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { useLocation } from 'react-router-dom'

import { getUserProfile, ProfileDTO } from '../../services/users'
import { ApiError, ReactQueryKey } from '../types'
import { getRefetchInterval, retryIfErrorIsNotConnectionOrDistributionGroup } from '../utils'

export function useGetUserProfile(): UseQueryResult<ProfileDTO, ApiError> {
  const location = useLocation()
  return useQuery<ProfileDTO, ApiError>(
    [ReactQueryKey.USER_PROFILE],
    async () => await getUserProfile(location.pathname),
    {
      cacheTime: 10000,
      notifyOnChangeProps: ['data', 'error'],
      refetchIntervalInBackground: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: (_, query) => getRefetchInterval(query.state.status, 10000),
      retry: (_, error) => retryIfErrorIsNotConnectionOrDistributionGroup(error),
    }
  )
}

export function useGetUserProfileForRequestAccessPage(): UseQueryResult<ProfileDTO, ApiError> {
  return useQuery<ProfileDTO, ApiError>(
    [ReactQueryKey.USER_PROFILE, 'Request access page'],
    async () => await getUserProfile('/request-access'),
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: ['data', 'error'],
      refetchIntervalInBackground: false,
      retry: false,
    }
  )
}

export const useQueryClientUserProfile = (): {
  profileDTO?: ProfileDTO
} => {
  const queryClient = useQueryClient()

  return {
    profileDTO: queryClient.getQueryData([ReactQueryKey.USER_PROFILE]),
  }
}
