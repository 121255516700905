import { LoadingScreen } from '@ppui/ui-components'
import { AppContainer } from 'components/AppContainer'
import { isNotNil } from 'ramda-adjunct'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from './contexts'
import { useHandleError } from './lib/hooks/useHandleError'
import { useGetUserProfile } from './lib/queries/useGetUserProfile'

export const RequireAuth = ({
  children,
  fullWidth = false,
}: {
  children: JSX.Element
  fullWidth?: boolean
}): JSX.Element => {
  const { isAuthenticated, hasLoaded } = useAuth()
  const location = useLocation()

  const { isLoading, error } = useGetUserProfile()

  const errorComponent = useHandleError(error)

  if (isNotNil(errorComponent)) return errorComponent

  if (!hasLoaded || isLoading) {
    return <LoadingScreen />
  }

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate
        to={`/login?${location?.pathname.slice(1) ?? ''}${location?.search}` ?? ''}
        replace
      />
    )
  }

  return (
    <AppContainer isAuthenticated fullWidth={fullWidth}>
      <div>{children}</div>
    </AppContainer>
  )
}
