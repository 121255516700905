import { AccountType } from 'src/lib/types'

import { API } from './aws-api'

export interface ProfileDTO {
  id: string
  email: string
  givenName: string
  familyName: string
  organizationId: string
  organizationName: string
  accountType: AccountType
  isOrderSyndicateEmailsSuppressed: boolean
}

export const getUserProfile = async (requestedFrom: string): Promise<ProfileDTO> => {
  return await API<ProfileDTO>().get({
    path: `/v1/profile`,
    init: {
      headers: { 'Requested-From': requestedFrom },
    },
  })
}

export async function requestAccess(): Promise<void> {
  void (await API().post({
    path: '/v1/profile/request-access',
    init: {
      headers: {
        'Requested-From': '/request-access',
      },
    },
  }))
}
